import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useI18n } from 'services/i18n';

const NotFoundPage = () => {
  const [t, language] = useI18n();

  return (
    <Layout>
      <SEO title={t('seo.title.404')} lang={language} description={t('seo.description.404')} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;
